
import {
  FileForUserPage,
} from 'src/views/fileUser'
import pathMap from 'src/paths/pathMap'

class FileForUserRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.FILE_FOR_USER_LIST_VIEW,
        component: FileForUserPage
      }
    ]
  }
}

export default FileForUserRoute
