import { TablePage, AddPage, EditPage } from 'src/views/billingconfig/BillingConfigPage'
import pathMap from 'src/paths/pathMap'

class BillingConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.BILLING_CONFIG_LIST_VIEW,
                component: TablePage
            }
        ]
    }
}

export default BillingConfigRoute