import ProductInfo from 'src/views/homepage/CustomTopBar/ProductInfo'
import pathMap from 'src/paths/pathMap'

class HomePageProductInfoRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_PRODUCT_INFO,
          component: ProductInfo
        }
      ]
    }
  }
  
  export default HomePageProductInfoRoute
