import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/nbcicanadainvoice'
import pathMap from 'src/paths/pathMap'

class NBCICanadaInvoiceRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.N_B_C_I_CANADA_INVOICE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.N_B_C_I_CANADA_INVOICE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.N_B_C_I_CANADA_INVOICE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default NBCICanadaInvoiceRoute
