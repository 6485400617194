import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/arrivalmessage'
import pathMap from 'src/paths/pathMap'

class ArrivalmessageRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ARRIVALMESSAGE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ARRIVALMESSAGE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ARRIVALMESSAGE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ARRIVALMESSAGE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default ArrivalmessageRoute
