import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/aes/partyscreening'
import pathMap from 'src/paths/pathMap'

class AesPartyScreeningRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.AES_PARTY_SCREENING_LIST_VIEW,
        component: ViewPage
      },
      {
        exact: true,
        path: pathMap.AES_PARTY_SCREENING_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.AES_PARTY_SCREENING_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.AES_PARTY_SCREENING_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default AesPartyScreeningRoute
