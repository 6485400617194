import {
  SummaryPage,
} from 'src/views/dashboard'
import pathMap from 'src/paths/pathMap'

class DashboardRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.DASHBOARD_SUMMARY,
        component: SummaryPage
      }
    ]
  }
}

export default DashboardRoute
