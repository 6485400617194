import {
  AddPage
} from 'src/views/dbadmin'
import pathMap from 'src/paths/pathMap'

class DBAdminAciAirRoute {
  getConfigurations() {
    return [ 
      {
        exact: true,
        path: pathMap.DBADMIN_ACIAIR,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.DBADMIN_ACIAIR_ADD_VIEW,
        component: AddPage
      },
    ]
  }
}

export default DBAdminAciAirRoute
