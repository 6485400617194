import UploadResponse from 'src/views/sbci/upload/UploadResponse'
import pathMap from 'src/paths/pathMap'

class SBCIUploadRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.SBCI_UPLOAD_LIST_VIEW,
        component: UploadResponse
      }
    ]
  }
}

export default SBCIUploadRoute
