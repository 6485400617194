import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/acihighway/statusquery'
import pathMap from 'src/paths/pathMap'

class AciHighwayStatusQueryRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACI_HW_STAT_QRY_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_STAT_QRY_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_STAT_QRY_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_STAT_QRY_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default AciHighwayStatusQueryRoute
