import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/acehighway/shipment'
import pathMap from 'src/paths/pathMap'
import ShipmentCSVUpload from 'src/views/acehighway/shipment/upload/ShipmentCSVUpload'

class AceHighwayShipmentRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACE_HIGHWAY_SHIPMENT_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACE_HIGHWAY_SHIPMENT_DETAILS_VIEW,
        component: ViewPage
      },
      {
        exact: true,
        path: pathMap.ACE_HIGHWAY_SHIPMENT_UPLOAD_VIEW,
        component: ShipmentCSVUpload
      }
    ]
  }
}

export default AceHighwayShipmentRoute
