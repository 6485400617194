import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/acihighway/releasenotice'
import pathMap from 'src/paths/pathMap'

class AciHwReleaseNoticeRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACI_HW_RELEASE_NOTICE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_RELEASE_NOTICE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_RELEASE_NOTICE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_RELEASE_NOTICE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default AciHwReleaseNoticeRoute
