import PrivacyPolicy from 'src/views/homepage/CustomFooter/PrivacyPolicy'
import pathMap from 'src/paths/pathMap'

class HomePagePrivacyPolicyRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_PRIVACY_POLICY,
          component: PrivacyPolicy
        }
      ]
    }
  }
  
  export default HomePagePrivacyPolicyRoute
