import UploadMasterData from 'src/views/partymaster/upload/UploadMasterData.js'
import pathMap from 'src/paths/pathMap'

class UploadMasterDataRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.PARTY_MASTER_UPLOAD_LIST_VIEW,
        component: UploadMasterData
      }
    ]
  }
}

export default UploadMasterDataRoute
