import CommunicationUtilPage from 'src/views/communication/CommunicationUtilPage'
import pathMap from 'src/paths/pathMap'

class CommunicationUtilRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.COMMUNICATION_UTIL,
                component: CommunicationUtilPage
            }
        ]
    }
}

export default CommunicationUtilRoute