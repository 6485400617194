import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/acehighway/abiInbond7512'
import pathMap from 'src/paths/pathMap'

class AceHwyAbiInbond7512Route {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ABI_INBOND_7512_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ABI_INBOND_7512_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ABI_INBOND_7512_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ABI_INBOND_7512_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}
export default AceHwyAbiInbond7512Route
