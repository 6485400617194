import UploadResponse from 'src/views/upload/UploadResponse'
import pathMap from 'src/paths/pathMap'

class UploadResponseRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.UPLOAD_RESPONSE_LIST_VIEW,
        component: UploadResponse
      }
    ]
  }
}

export default UploadResponseRoute
