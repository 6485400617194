import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/sbci/manifest'
import pathMap from 'src/paths/pathMap'

class SbciManifestRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.SBCI_MANIFEST_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.SBCI_MANIFEST_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.SBCI_MANIFEST_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.SBCI_MANIFEST_DETAILS_VIEW,
        component: ViewPage
      },
    ]
  }
}

export default SbciManifestRoute
