import {
    AddPage,
    EditPage,
    ViewPage,
  } from 'src/views/arrivalmessage/highway'
  import {
    TablePage
  } from 'src/views/arrivalmessage'
  import pathMap from 'src/paths/pathMap' 
  
  class AciHighwayArrivalRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.ACI_HIGHWAY_ARRIVAL_LIST_VIEW,
          component: TablePage
        },
        {
          exact: true,
          path: pathMap.ACI_HIGHWAY_ARRIVAL_ADD_VIEW,
          component: AddPage
        },
        {
          exact: true,
          path: pathMap.ACI_HIGHWAY_ARRIVAL_EDIT_VIEW,
          component: EditPage
        },
        {
          exact: true,
          path: pathMap.ACI_HIGHWAY_ARRIVAL_DETAILS_VIEW,
          component: ViewPage
        }
      ]
    }
  }
  
  export default AciHighwayArrivalRoute
  