import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/acihighway/wacmManifestForward'
import pathMap from 'src/paths/pathMap'

class WACMManifestForwardRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.WACM_MF_NOTICE_PATH_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.WACM_MANIFEST_FORWARD_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default WACMManifestForwardRoute