import AboutUs from 'src/views/homepage/CustomTopBar/AboutUs'
import pathMap from 'src/paths/pathMap'

class HomePageAboutUsRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_ABOUT_US,
          component: AboutUs
        }
      ]
    }
  }
  
  export default HomePageAboutUsRoute
