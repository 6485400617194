import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
  } from 'src/views/arrivalmessage'
  import pathMap from 'src/paths/pathMap' 
  
  class AirArrivalRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.AIR_ARRIVAL_LIST_VIEW,
          component: TablePage
        },
        {
          exact: true,
          path: pathMap.AIR_ARRIVAL_ADD_VIEW,
          component: AddPage
        },
        {
          exact: true,
          path: pathMap.AIR_ARRIVAL_EDIT_VIEW,
          component: EditPage
        },
        {
          exact: true,
          path: pathMap.AIR_ARRIVAL_DETAILS_VIEW,
          component: ViewPage
        }
      ]
    }
  }
  
  export default AirArrivalRoute
  