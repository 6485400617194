import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/ehbl/housebill'
import pathMap from 'src/paths/pathMap'

class EhblHouseBillRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.EHBL_HOUSE_BILL_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.EHBL_HOUSE_BILL_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.EHBL_HOUSE_BILL_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.EHBL_HOUSE_BILL_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default EhblHouseBillRoute