import UploadResponse from 'src/views/docgen/upload/UploadResponse'
import pathMap from 'src/paths/pathMap'

class DocGenUploadRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.DOC_GEN_UPLOAD_LIST_VIEW,
        component: UploadResponse
      }
    ]
  }
}

export default DocGenUploadRoute
