import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/acihighway/cargo'
import pathMap from 'src/paths/pathMap'
import UploadCargo from 'src/views/acihighway/cargo/upload/CargoCSVUpload'

class AciHighwayCargoRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACI_HW_CARGO_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_CARGO_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_CARGO_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_CARGO_DETAILS_VIEW,
        component: ViewPage
      },
      {
        exact: true,
        path: pathMap.ACI_HW_CARGO_UPLOAD_LIST_VIEW,
        component: UploadCargo
      }
    ]
  }
}

export default AciHighwayCargoRoute
