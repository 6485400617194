import { TablePage, AddPage, EditPage, ViewPage } from 'src/views/ehbl/closemsg'
  import pathMap from 'src/paths/pathMap'
  
  class EhblCloseMsgRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.EHBL_CLOSE_MSG_LIST_VIEW,
          component: TablePage
        },
        {
          exact: true,
          path: pathMap.EHBL_CLOSE_MSG_ADD_VIEW,
          component: AddPage
        },
        {
          exact: true,
          path: pathMap.EHBL_CLOSE_MSG_EDIT_VIEW,
          component: EditPage
        },
        {
          exact: true,
          path: pathMap.EHBL_CLOSE_MSG_DETAILS_VIEW,
          component: ViewPage
        }
      ]
    }
  }
  
  export default EhblCloseMsgRoute
  