import TermsOfServices from 'src/views/homepage/CustomFooter/TermsOfServices'
import pathMap from 'src/paths/pathMap'

class HomePageTermsOfServicesRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_TERMS_OF_SERVICES,
          component: TermsOfServices
        }
      ]
    }
  }
  
  export default HomePageTermsOfServicesRoute
