import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/aciocean/arrivalmessage'
import pathMap from 'src/paths/pathMap'

class AciOceanArrivalMessageRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACI_OCN_ARRIVAL_MESSAGE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACI_OCN_ARRIVAL_MESSAGE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACI_OCN_ARRIVAL_MESSAGE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACI_OCN_ARRIVAL_MESSAGE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default AciOceanArrivalMessageRoute
