  import ArrivalResponse from 'src/views/arrivalmessage/ArrivalResponse'
  import pathMap from 'src/paths/pathMap'
  
  class ArrivalResponseRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.ARRIVAL_RESPONSE_LIST_VIEW,
          component: ArrivalResponse
        }
      ]
    }
  }
  
  export default ArrivalResponseRoute
  