import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/conveyancemaster/'
import pathMap from 'src/paths/pathMap'

class ConveyanceMasterRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CONV_MAST_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.CONV_MAST_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.CONV_MAST_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.CONV_MAST_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default ConveyanceMasterRoute
