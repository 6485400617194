import TermsOfUse from 'src/views/homepage/CustomFooter/TermsOfUse'
import pathMap from 'src/paths/pathMap'

class HomePageTermsOfUseRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_TERMS_OF_USE,
          component: TermsOfUse
        }
      ]
    }
  }
  
  export default HomePageTermsOfUseRoute
