import TradeResources from 'src/views/homepage/CustomTopBar/TradeResources'
import pathMap from 'src/paths/pathMap'

class HomePageTradeResourcesRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_TRADE_RESOURCES,
          component: TradeResources
        }
      ]
    }
  }
  
export default HomePageTradeResourcesRoute
