import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/fdapn/productmaster'
import pathMap from 'src/paths/pathMap'

class FdapnRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.FDAPN_PRODUCTMASTER_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.FDAPN_PRODUCTMASTER_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.FDAPN_PRODUCTMASTER_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.FDAPN_PRODUCTMASTER_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default FdapnRoute
