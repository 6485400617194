import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/nbci/usinvoice'
import pathMap from 'src/paths/pathMap'

class NbciUsInvoiceRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.NBCI_US_INVOICE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.NBCI_US_INVOICE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.NBCI_US_INVOICE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.NBCI_US_INVOICE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default NbciUsInvoiceRoute
