import FAQ from 'src/views/homepage/CustomTopBar/FAQ'
import pathMap from 'src/paths/pathMap'

class HomePageFAQRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_FAQ,
          component: FAQ
        }
      ]
    }
  }
  
export default HomePageFAQRoute
