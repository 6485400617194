import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/a6a'
import pathMap from 'src/paths/pathMap'

class A6aRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.A6A_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.A6A_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.A6A_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.A6A_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default A6aRoute
