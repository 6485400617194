import {
    TablePage
  } from 'src/views/acihighway/d4Notices'
  import pathMap from 'src/paths/pathMap'
  
  class EhblD4NoticeRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.EHBL_D4_NOTICE_LIST_VIEW,
          component: TablePage
        }
      ]
    }
  }
  
  export default EhblD4NoticeRoute
  