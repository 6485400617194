import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/aceisf/isf10'
import pathMap from 'src/paths/pathMap'

class AceISF10Route {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACEISF10_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACEISF10_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACEISF10_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACEISF10_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default AceISF10Route
