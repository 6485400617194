import ContactUs from 'src/views/homepage/CustomTopBar/ContactUs'
import pathMap from 'src/paths/pathMap'

class HomePageContactUsRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.HOME_PAGE_CONTACT_US,
          component: ContactUs
        }
      ]
    }
  }
  
  export default HomePageContactUsRoute
