import {
  TablePage
} from 'src/views/aciair/validationerrorlog'
import pathMap from 'src/paths/pathMap'

class ValidationErrorLogRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.VALIDATION_ERROR_LOG_LIST_VIEW,
        component: TablePage
      }
    ]
  }
}

export default ValidationErrorLogRoute
