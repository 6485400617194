import FileUser from 'src/views/fileUser/FileUser'
import pathMap from 'src/paths/pathMap'

class FileUserRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.FILE_USER_LIST_VIEW,
        component: FileUser
      }
    ]
  }
}

export default FileUserRoute
