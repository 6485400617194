import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/ehbl/manifestforward'
import pathMap from 'src/paths/pathMap'

class EhblManifestForwardRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.EHBL_MANIFEST_FORWARD_LIST_VIEW,
        component: TablePage
      },
      /*{
        exact: true,
        path: pathMap.EHBL_MANIFEST_FORWARD_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.EHBL_MANIFEST_FORWARD_EDIT_VIEW,
        component: EditPage
      },*/
      {
        exact: true,
        path: pathMap.EHBL_MANIFEST_FORWARD_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default EhblManifestForwardRoute