import UploadCargo from 'src/views/acihighway/cargo/upload/CargoCSVUpload.js'
import pathMap from 'src/paths/pathMap'

class CargoUploadRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACI_HW_CARGO_UPLOAD_LIST_VIEW,
        component: UploadCargo
      }
    ]
  }
}

export default CargoUploadRoute
