import {
    TablePage
  } from 'src/views/acihighway/d4Notices'
  import pathMap from 'src/paths/pathMap'
  
  class WACMD4NoticeRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.WACM_D4_NOTICE_LIST_VIEW,
          component: TablePage
        }
      ]
    }
  }
  
  export default WACMD4NoticeRoute
  