import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/partymaster'
import pathMap from 'src/paths/pathMap'
import { UploadPage } from 'src/views/partymaster/upload'

class PartyMasterRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.PARTY_MASTER_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.PARTY_MASTER_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.PARTY_MASTER_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.PARTY_MASTER_DETAILS_VIEW,
        component: ViewPage
      },
      {
        exact: true,
        path: pathMap.PARTY_MASTER_UPLOAD_LIST_VIEW,
        component: UploadPage
      }
    ]
  }
}

export default PartyMasterRoute
